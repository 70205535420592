import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '/react/test/src/Login.css';

const Login = () => {
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('admin@azai.com');
    const [password, setPassword] = useState('1234');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleIdChange = (event) => {
        setUserId(event.target.value);
    };
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // 로그인 계정 정보
    const user = {
        "userId" : userId,
        "password" : password
    };

    // API를 호출하여 사용자 인증 처리
    const authenticateUser = async (userId, password) => {
    try {
        // 로컬 : 'http://localhost:7070/user/login'
        // 도커 : 'http://nodejs:8000/user/login'
        console.log("로그인 시도")
        // https://popbio.ai/api/aicc/sceq
        
        const response = await fetch('https://popbio.ai/api/user/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            throw new Error('네트워크 응답이 올바르지 않습니다.');
        }

        const data = await response.json();
        console.log("로그인 결과");
        console.log(data);
        
        // API 응답에서 성공 여부와 사용자 ID를 반환
        if (data.success) {
            return { 
                success: true,
                userNo: data.userNo,
                userId: data.userId,
                name: data.name,
                macAddr: data.macAddr };
            } else {
            return { success: false, message: data.message };
        }
    } catch (error) {
        console.error('인증 중 오류 발생:', error);
        return { success: false, message: '오류가 발생했습니다. 나중에 다시 시도해 주세요.' };
    }
    };

    // 폼 제출 처리
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        const result = await authenticateUser(userId, password);

        if (result.success) {
            // 인증 성공 시 /AICC 페이지로 리다이렉트
            console.log("로그인 성공!!!")
            navigate('/AICC', { state: { user: result } });
        } else {
            console.log("로그인 실패...")
            
            // 인증 실패 시 오류 메시지 표시
            setError(`로그인 실패... (err : ${result.message})`);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
            <h2>Login</h2>
            <div className="form-group">
                <label>아이디</label>
                <input
                    type="text"
                    value={userId}
                    onChange={handleIdChange}
                    required
                />
            </div>
            <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                />
            </div>
            <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;