// components/UserList.jsx
import React, { useEffect } from 'react';

const UserList = ({
  phoneNumberList, 
  selectedPhoneNumber, 
  setSelectedPhoneNumber,                            
  selectedMessageList, 
  selectedMessageList_json,
  selectedMessageList_json2,
  connData,
  userNo,
  isCallActive
}) => {

  useEffect(() => {
    //console.log('connData :::', connData);
  }, [connData]);

  // 상담고객 번호 클릭 이벤트
  const handlePhoneNumberClick = (phoneNumber, index) => {
    /*
    connData.map((data) => {
      console.log(`connData [${index}] ::: ${data.caller}`);
    }); 
    */

    setSelectedPhoneNumber(phoneNumber);
    
    const seqNos = connData[index].seq_nos;
    console.log(`[UserList] handlePhoneNumberClick index: ${index} , phoneNumber: ${phoneNumber}`);
  
    //selectedMessageList(userNo, seqNos);
    //selectedMessageList_json(userNo, seqNos);
    selectedMessageList_json2(userNo, phoneNumber);
  };

  return (
    <div className="user-list">
      <h5 className="user-list-header-name">상담 고객</h5>

      <ul style={{ pointerEvents: isCallActive ? 'none' : 'auto', opacity: isCallActive ? 0.5 : 1 }}>
        {phoneNumberList.map((phoneNumber, index) => (
          <li
            key={index}
            onClick={() => handlePhoneNumberClick(phoneNumber, index)}
            
            className={selectedPhoneNumber === phoneNumber ? 'selected' : ''}
          >
            {phoneNumber}
          </li>
        ))}
      </ul>

    </div>
  );
};

export default UserList;